import {
  CHANNEL,
  REAL_TIME_EVENT,
  REAL_TIME_PRIVATE_EVENT,
  POST_CREATED,
  POST_DELETED,
  MESSAGE_CREATED,
  MESSAGE_VIEWED,
  FORCE_LOGOUT,
  PAGE_RELOAD,
  RECONNECT_SOCKET,
  ONLINE_USER_COUNT,
  NEWS_CREATED,
  NEWS_UPDATED,
  NEWS_DELETED,
} from "@/constants/events";
import EventBus from "@/bus/eventBus";

const listeners = {
  mounted() {
    if (window.Echo) {
      console.log("this.$options.name: ", this.$options.name);
      window.Echo.channel(CHANNEL).listen(REAL_TIME_EVENT, (event) => {
        if (event.event === RECONNECT_SOCKET) {
          console.log(RECONNECT_SOCKET);
        }
        if (event.event === NEWS_CREATED || event.event === NEWS_UPDATED || event.event === NEWS_DELETED) {
          console.log(this.$options.name, event.event);
          const sound = require("../assets/news.mp3");
          new Audio(sound).play();
          this.$root.$emit('updateUnreadnews');
          this.$root.$emit('reloadFetchNews');
        }
      });
      if (this.$options.name === "Posts") {
        window.Echo.channel(CHANNEL).listen(REAL_TIME_EVENT, (event) => {
          if (event.event === POST_CREATED || event.event === POST_DELETED) {
            if (event.event === POST_CREATED) {
              console.log(this.$options.name, POST_CREATED);
              if (event.data.owner_id !== this.user.id) {
                const sound = require("../assets/redline_honk.mp3");
                new Audio(sound).play();
              }
            } else {
              console.log(this.$options.name, POST_DELETED);
            }
            this.page = 1;
            this.fetchPosts();
            this.$root.$emit('updateFooterBanner');
            this.$root.$emit('checkPaymentStatus');
          }
          if (event.event === ONLINE_USER_COUNT) {
            EventBus.$emit("PARTICIPANT_COUNT", event.data.online_users_count);
          }
        });
      }

      // eslint-disable-next-line no-unused-vars
      // window.Echo.join(`online-users`)
      //   .here((users) => {
      //     sessionStorage.setItem(
      //       "onlineUserIds",
      //       JSON.stringify(users.map((user) => user.id))
      //     );
      //     // eslint-disable-next-line no-undef
      //     EventBus.$emit(
      //       "PARTICIPANT_COUNT",
      //       users.map((user) => user.id).length
      //     );
      //   })
      //   .joining((user) => {
      //     let onlineUserIds = JSON.parse(
      //       sessionStorage.getItem("onlineUserIds")
      //     );
      //     if (!onlineUserIds.some((id) => id === user.id)) {
      //       onlineUserIds.push(user.id);
      //     }
      //     sessionStorage.setItem(
      //       "onlineUserIds",
      //       JSON.stringify(onlineUserIds)
      //     );
      //     EventBus.$emit("PARTICIPANT_COUNT", onlineUserIds.length);
      //   })
      //   .leaving((user) => {
      //     let onlineUserIds = JSON.parse(
      //       sessionStorage.getItem("onlineUserIds")
      //     );
      //     onlineUserIds = onlineUserIds.filter((id) => user.id !== id);
      //     sessionStorage.setItem(
      //       "onlineUserIds",
      //       JSON.stringify(onlineUserIds)
      //     );
      //     EventBus.$emit("PARTICIPANT_COUNT", onlineUserIds.length);
      //   })
      //   .error((error) => {
      //     console.error("error", error);
      //   });

      window.Echo.private(`${CHANNEL}.${this.user.id}`).listen(
        REAL_TIME_PRIVATE_EVENT,
        (event) => {
          console.log("-------- Logout --------");
          switch (event.event) {
            case POST_CREATED:
              if (this.$options.name === "Posts") {
                console.log(this.$options.name, POST_CREATED);
                console.log(event.data, this.user);
                if (event.data.owner_id == this.user.id) {
                  const sound = require("../assets/redline_honk.mp3");
                  new Audio(sound).play();
                  this.page = 1;
                  this.fetchPosts();
                }
              }
              break;
            case MESSAGE_CREATED:
              if (this.$options.name === "Posts") {
                this.badgeCount = event.data.unReadMessagesCount;
                console.log(this.$options.name, MESSAGE_CREATED);
              }
              if (this.$options.name === "Messages") {
                this.fetchMessages();
                console.log(this.$options.name, MESSAGE_CREATED);
              }
              if (this.$options.name === "MessageCenter") {
                this.page = 1;
                this.fetchPostThreads();
                console.log(this.$options.name, MESSAGE_CREATED);
              }
              break;

            case MESSAGE_VIEWED:
              if (this.$options.name === "Posts") {
                this.badgeCount = event.data.unReadMessagesCount;
                console.log(this.$options.name, MESSAGE_VIEWED);
              }
              break;

            case FORCE_LOGOUT:
              console.log("logout before condition, event.data.token: ", event.data.token);
              if (
                this.$options.name === "Navbar" &&
                event.data.token === localStorage.getItem("access_token")
              ) {
                console.log("logout inside condition");
                this.doLogout();
              }
              break;

            case PAGE_RELOAD:
              window.location.reload();
              break;
          }
        }
      );
    }
  },
};

export default { listeners };
