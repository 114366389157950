export const CHANNEL = "User";
export const REAL_TIME_EVENT = "RealTimeEvent";
export const REAL_TIME_PRIVATE_EVENT = "RealTimePrivateEvent";
export const POST_CREATED = "post.created";
export const POST_DELETED = "post.deleted";
export const MESSAGE_CREATED = "message.created";
export const MESSAGE_VIEWED = "message.viewed";
export const FORCE_LOGOUT = "force.logout";
export const PAGE_RELOAD = "page.reload";
export const RECONNECT_SOCKET = "reconnect.socket";
export const ONLINE_USER_COUNT = "online_users_count.updated";
export const NEWS_CREATED = "news.created";
export const NEWS_UPDATED = "news.updated";
export const NEWS_DELETED = "news.deleted";
